import { Component, OnInit } from "@angular/core";
import { User } from "src/models/user";
import { FormBuilder, FormGroup } from "@angular/forms";
import { KeyService } from "src/services/key.service";
import { Key } from "src/models/key";
import { MatDialogRef } from "@angular/material";
import { UserService } from "src/services/user.service";

@Component({
  selector: "app-create-key",
  templateUrl: "./create-key.component.html",
  styleUrls: ["./create-key.component.scss"],
})
export class CreateKeyComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private keyService: KeyService,
    private dialogRef: MatDialogRef<CreateKeyComponent>,
    private data: UserService
  ) {
    this.form = this.fb.group({
      facilitycode: [""],
      cardcode: [""],
      variant: [""],
      user: [""],
      isactive: [Boolean],
    });
  }
  Variants: any = ["Fob", "Card"];
  Users: User[] = [];

  ngOnInit() {
    this.AddKey();
    this.getUsers();
    this.form.patchValue({ isactive: true });
  }

  AddKey() {
    this.form = this.fb.group({
      facilitycode: [""],
      cardcode: [""],
      variant: [""],
      user: [""],
      isactive: [""],
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.submitForm();
  }
  getUsers() {
    this.data.getAllUsers().subscribe((data: User[]) => {
      this.Users = data;
    });
  }

  postKey() {
    this.keyService.getAllKeys().subscribe((res) => console.log(res));
  }

  submitForm() {
    var formData: any = new FormData();
    formData.append("facilitycode", this.form.get("facilitycode").value);
    formData.append("cardcode", this.form.get("cardcode").value);
    formData.append("variant", this.form.get("variant").value);
    formData.append("user", this.form.get("user").value);
    formData.append("isactive", this.form.get("isactive").value);

    const newKey: Key = Object.assign({}, this.form.value);
    console.log("New key: ", newKey);
    this.keyService.createKey(newKey).subscribe((res) => {
      console.log("Key has been created", res);
      this.dialogRef.close(true);
    });
  }
}
