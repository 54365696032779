import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { UsersComponent } from "./users/users.component";
import { MatButtonModule } from "@angular/material";
import { CreateUserComponent } from "./create-user/create-user.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MaterialModule } from "../material";
import { Routes, RouterModule } from "@angular/router";
import { MatDialogModule } from "@angular/material";
import { DeviceComponent } from "./device/device.component";
import { LogComponent } from "./log/log.component";
import { DoorComponent } from "./door/door.component";
import { AppRoutingModule } from "./app-routing.module";
import { KeyComponent } from "./key/key.component";
import { CreateKeyComponent } from "./create-key/create-key.component";
import { LoginComponent } from "./login/login.component";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CreateDoorComponent } from "./create-door/create-door.component";
import { NavToolbarComponent } from "./nav-toolbar/nav-toolbar.component";
import { GroupComponent } from "./group/group.component";
import { EditUserGroupComponent } from "./editusergroup/editusergroup.component";
import { EditDoorGroupComponent } from "./editdoorgroup/editdoorgroup.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { FirmwareDialogComponent } from "./firmware-dialog/firmware-dialog.component";
import { UpdateDoorComponent } from "./update-door/update-door.component";
import { UpdatedevicesfirmwareDialogComponent } from "./updatedevicesfirmware-dialog/updatedevicesfirmware-dialog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    CreateUserComponent,
    DeviceComponent,
    LogComponent,
    DoorComponent,
    KeyComponent,
    CreateKeyComponent,
    LoginComponent,
    CreateDoorComponent,
    NavToolbarComponent,
    GroupComponent,
    EditUserGroupComponent,
    EditDoorGroupComponent,
    ConfirmDialogComponent,
    FirmwareDialogComponent,
    UpdateDoorComponent,
    UpdatedevicesfirmwareDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTableModule,
    RouterModule,
    MatDialogModule,
    AppRoutingModule,
    FlexLayoutModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateUserComponent,
    CreateKeyComponent,
    CreateDoorComponent,
    EditUserGroupComponent,
    EditDoorGroupComponent,
    ConfirmDialogComponent,
    FirmwareDialogComponent,
    UpdateDoorComponent,
    UpdatedevicesfirmwareDialogComponent,
  ],
})
export class AppModule {}
