import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Key } from "src/models/key";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class KeyService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;

  public getAllKeys(): Observable<Key[]> {
    let url = this.baseUrl + "keys";
    return this.httpService.get<Key[]>(url);
  }

  public createKey(key: Key) {
    let url = this.baseUrl + "Keys";
    return this.httpService
      .post(url, key)
      .pipe(catchError(() => throwError("Key Could not be created!")));
  }

  public KeyUpdateStatus(Id: Number, active: Boolean) {
    let url = this.baseUrl + "Keys/" + Id + "/active";
    return this.httpService.patch(url, { active });
  }

  public deleteKey(id: Number) {
    let url = this.baseUrl + "Keys/" + id;
    return this.httpService
      .delete(url)
      .pipe(catchError(() => throwError("Could not delete Key!")));
  }
}
