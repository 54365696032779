import { Component, OnInit } from "@angular/core";
import { KeyService } from "src/services/key.service";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { Key, KeyVariant } from "src/models/key";
import { CreateKeyComponent } from "../create-key/create-key.component";

@Component({
  selector: "app-key",
  templateUrl: "./key.component.html",
  styleUrls: ["./key.component.scss"]
})
export class KeyComponent implements OnInit {
  constructor(private data: KeyService, public dialog: MatDialog) {}

  keys: Key[] = [];
  displayedColumns = [
    "user",
    "cardcode",
    "variant",
    "facilitycode",
    "actionColumn",
    "deleteColumn"
  ];
  dataSource = new MatTableDataSource();
  KeyVariant = KeyVariant;

  refresh() {
    console.log("Running Key Refresh...");
    this.data.getAllKeys().subscribe((data: Key[]) => {
      console.log("Keys: ", data);
      this.dataSource.data = data;
    });
  }
  ngOnInit() {
    this.refresh();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateKeyComponent);
    dialogRef.afterClosed().subscribe(data => {
      console.log("Data", data);
      if (data) this.refresh();
    });
  }
  UpdateKeyStatus(Id: Number, active: Boolean) {
    this.data.KeyUpdateStatus(Id, active).subscribe(res => {
      this.refresh();
    });
  }

  DeleteKey(id: Number) {
    this.data.deleteKey(id).subscribe(() => {
      console.log("Deleted Key With ID: ", id);
    });
  }
}
