import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef
} from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { Door } from "src/models/door";
import { Observable } from "rxjs";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Usergroup } from "src/models/usergroup";
import {
  MatAutocomplete,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent
} from "@angular/material";
import { GroupService } from "src/services/group.service";
import { Doorgroup } from "src/models/doorgroup";
import { DoorService } from "src/services/door.service";
import { startWith, map } from "rxjs/operators";

@Component({
  selector: "app-editdoorgroup",
  templateUrl: "./editdoorgroup.component.html",
  styleUrls: ["./editdoorgroup.component.scss"]
})
export class EditDoorGroupComponent implements OnInit {
  form: FormGroup;
  AllDoors: Door[] = [];
  Doors: Door[] = [];
  filteredDoors: Observable<Door[]>;
  doorCtrl = new FormControl();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // User Group
  AllUserGroups: Usergroup[] = [];
  UserGroups: Usergroup[] = [];
  filteredUserGroups: Observable<Usergroup[]>;
  usergroupCtrl = new FormControl();

  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private fb: FormBuilder,
    private groupService: GroupService,
    private doorService: DoorService,
    private dialogRef: MatDialogRef<EditDoorGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Doorgroup
  ) {
    this.form = this.fb.group({
      name: [""]
    });
    this.filteredDoors = this.doorCtrl.valueChanges.pipe(
      startWith(null),
      map((door: Door | null) =>
        door ? this._filter(door) : this.AllDoors.slice()
      )
    );
    this.filteredUserGroups = this.usergroupCtrl.valueChanges.pipe(
      startWith(null),
      map((usergroup: Usergroup | null) =>
        usergroup
          ? this._filterusergroup(usergroup)
          : this.AllUserGroups.slice()
      )
    );
  }

  ngOnInit() {
    this.getDoors();
    this.getUserGroups();

    if (this.data) {
      this.Doors = this.data.doors;
      this.UserGroups = this.data.userGroups;
      this.form.patchValue({
        name: this.data.name
      });
    }
  }

  getDoors() {
    this.doorService.getAllDoors().subscribe((data: Door[]) => {
      this.AllDoors = data;
      this.Doors.forEach(u => {
        this.handleFilteredDoors(u);
      });
    });
  }

  getUserGroups() {
    this.groupService.getUserGroups().subscribe((data: Usergroup[]) => {
      this.AllUserGroups = data;
      this.UserGroups.forEach(u => {
        this.handleFilteredUserGroup(u);
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.submitForm();
  }

  submitForm() {
    const newDoorGroup: Doorgroup = Object.assign({}, this.form.value);
    var formData: any = new FormData();
    formData.append("name", this.form.get("name").value);
    newDoorGroup.id = null;
    if (this.data && this.data.id) {
      newDoorGroup.id = this.data.id;
    }
    newDoorGroup.doors = this.Doors;
    newDoorGroup.userGroups = this.UserGroups;

    this.groupService.createDoorGroup(newDoorGroup).subscribe(res => {
      this.dialogRef.close(true);
    });
  }

  // DOOR CHIP AUTO COMPLETE

  handleFilteredDoors(door: Door) {
    let idx = this.AllDoors.map(e => {
      return e.id;
    }).indexOf(door.id);
    if (idx >= 0) {
      this.AllDoors.splice(idx, 1);
    }
  }

  @ViewChild("doorInput", { static: false }) doorInput: ElementRef<
    HTMLInputElement
  >;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      return null;
    }

    this.doorCtrl.setValue(null);
  }

  remove(door, indx): void {
    this.Doors.splice(indx, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.handleFilteredDoors(event.option.value);
    this.Doors.push(event.option.value);
    this.doorInput.nativeElement.value = "";
    this.doorCtrl.setValue(null);
  }

  private _filter(value: any): any[] {
    return this.AllDoors.filter(door => door.name.includes(value));
  }

  // USER GROUP AUTO COMLETE
  @ViewChild("usergroupInput", { static: false }) usergroupInput: ElementRef<
    HTMLInputElement
  >;

  addusergoup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      return null;
    }

    if (input) {
      input.value = "";
    }

    this.usergroupCtrl.setValue(null);
  }

  removeusergroup(usergroup, indx): void {
    this.UserGroups.splice(indx, 1);
  }

  selectedusergroup(event: MatAutocompleteSelectedEvent): void {
    this.handleFilteredUserGroup(event.option.value);
    this.UserGroups.push(event.option.value);
    this.usergroupInput.nativeElement.value = "";
    this.usergroupCtrl.setValue(null);
  }

  private _filterusergroup(value: any): any[] {
    return this.AllUserGroups.filter(usergroup =>
      usergroup.name.includes(value)
    );
  }

  handleFilteredUserGroup(usergroup: Usergroup) {
    let idx = this.AllUserGroups.map(e => {
      return e.id;
    }).indexOf(usergroup.id);
    if (idx >= 0) {
      this.AllUserGroups.splice(idx, 1);
    }
  }
}
