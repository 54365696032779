import { Component, OnInit, Inject } from "@angular/core";
import { DeviceService } from "src/services/device.service";
import { Firmware } from "src/models/firmware";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Device } from "src/models/device";
@Component({
  selector: "app-updatedevicesfirmware-dialog",
  templateUrl: "./updatedevicesfirmware-dialog.component.html",
  styleUrls: ["./updatedevicesfirmware-dialog.component.scss"],
})
export class UpdatedevicesfirmwareDialogComponent implements OnInit {
  constructor(
    private data: DeviceService,
    private dialogRef: MatDialogRef<UpdatedevicesfirmwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public devices: Device[]
  ) {}
  firmware: Firmware[] = [];
  ngOnInit() {
    this.getFirmwares();
  }

  getFirmwares() {
    this.data.getDeviceFirmwares().subscribe((data: Firmware[]) => {
      this.firmware = data;
    });
  }

  // Rewrite method to update ALL devices instead.
  UpdateDevicesFirmware(firmware: Firmware) {
    this.devices.forEach((device) => {
      this.data.UpdateDeviceFirmware(device.id, firmware).subscribe();
    });
    this.dialogRef.close();
    // Create toast that all devices has started updating firmware...
  }
}
