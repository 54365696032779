import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Door } from "../models/door";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DoorService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;
  public getAllDoors(): Observable<Door[]> {
    let url = this.baseUrl + "Doors";
    return this.httpService.get<Door[]>(url);
  }

  public CreateDoor(door: Door) {
    let url = this.baseUrl + "Doors";
    return this.httpService.post<Door>(url, door);
  }

  public DeleteDoor(id: Number) {
    let url = this.baseUrl + "Doors/" + id;
    return this.httpService
      .delete(url)
      .pipe(catchError(() => throwError("Could not delete Door!")));
  }

  public UpdateDoor(id: Number, door: Door) {
    let url = this.baseUrl + "Doors/" + id;
    return this.httpService
      .put(url, door)
      .pipe(catchError(() => throwError("Could not Update door!")));
  }
}
