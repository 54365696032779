import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Device } from "../models/device";
import { Firmware } from "src/models/firmware";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;
  public getAllDevices(): Observable<Device[]> {
    let url = this.baseUrl + "devices";
    return this.httpService.get<Device[]>(url);
  }

  public PingDevice(id: Number) {
    let url = this.baseUrl + "devices/" + id + "/ping";
    console.log("Pinging Device on url: " + url);
    return this.httpService.get(url, { responseType: "text" });
  }

  public getDeviceFirmwares(): Observable<Firmware[]> {
    let url = this.baseUrl + "firmware";
    return this.httpService.get<Firmware[]>(url);
  }

  public UpdateDeviceFirmware(id: Number, firmware: Firmware) {
    let url = this.baseUrl + "devices/" + id + "/update";
    console.log("Updating Device on Url: ", url);
    console.log("updating with firmware: ", firmware);
    return this.httpService
      .post(url, firmware)
      .pipe(catchError(() => throwError("Cannot update Device!")));
  }

  public UpdateAllDeviceFirmware(deviceIds: Number[], firmware: Firmware) {
    deviceIds.forEach((id: Number) => {
      let url = this.baseUrl + "devices/" + id + "/update";
      this.httpService
        .post(url, firmware)
        .pipe(
          catchError(() =>
            throwError("Failed updating Firmware on device with Id:" + id)
          )
        );
    });
  }

  public SynchronizeDeviceDB(id: Number) {
    let url = this.baseUrl + "devices/" + id + "/synchronize";
    return this.httpService.get(url);
  }
}
