import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable, throwError } from "rxjs";
import { Log } from "../models/log";
import { HttpClient } from "@angular/common/http";

//
//
@Injectable({
  providedIn: "root"
})
export class LogService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;

  public getAllLogs(): Observable<Log[]> {
    let url = this.baseUrl + "Log";
    return this.httpService.get<Log[]>(url);
  }

  // TODO CRUD Methods
}
