import { Component, OnInit } from "@angular/core";
import { DoorService } from "src/services/door.service";
import { Door } from "src/models/door";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { CreateDoorComponent } from "../create-door/create-door.component";
import { UpdateDoorComponent } from "../update-door/update-door.component";

@Component({
  selector: "app-door",
  templateUrl: "./door.component.html",
  styleUrls: ["./door.component.scss"],
})
export class DoorComponent implements OnInit {
  constructor(private data: DoorService, public dialog: MatDialog) {}

  doors: Door[] = [];
  displayedColumns = [
    "name",
    "device",
    "doornumber",
    "editColumn",
    "deleteAction",
  ];
  dataSource = new MatTableDataSource<Door>();

  refresh() {
    console.log("Running Door Refresh...");
    this.data.getAllDoors().subscribe((data: Door[]) => {
      console.log("Doors: ", data);
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.refresh();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateDoorComponent);
    dialogRef.afterClosed().subscribe((data) => {
      console.log("Data", data);
      if (data) this.refresh();
    });
  }

  editDoor(door) {
    const dialogRef = this.dialog.open(UpdateDoorComponent, {
      data: door,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.refresh();
    });
  }

  deleteDoor(id: Number) {
    this.data.DeleteDoor(id).subscribe((data) => {
      this.refresh();
    });
  }
}
