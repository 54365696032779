import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { LoginUser } from "src/models/user";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<LoginUser>;
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  public currentUser: Observable<LoginUser>;

  constructor(private http: HttpClient, private router: Router) {
    let user = new LoginUser();
    user.access_token = localStorage.getItem("access_token");

    this.currentUserSubject = new BehaviorSubject<LoginUser>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginUser {
    return this.currentUserSubject.value;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  setLoggedInStatus(status: boolean): void {
    this.loggedIn.next(status);
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrl}auth/login`, {
        username,
        password
      })
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("access_token", user.access_token);
          this.currentUserSubject.next(user);
          this.loggedIn.next(true);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("access_token");
    this.currentUserSubject.next(null);
    this.loggedIn.next(false);
    this.router.navigate(["/login"]);
  }
}
