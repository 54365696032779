import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { GroupService } from "src/services/group.service";
import {
  MatDialogRef,
  MatChipInputEvent,
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MAT_DIALOG_DATA
} from "@angular/material";
import { User } from "src/models/user";
import { UserService } from "src/services/user.service";
import { Usergroup } from "src/models/usergroup";
import { Doorgroup } from "src/models/doorgroup";
import { Observable } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { startWith, map } from "rxjs/operators";

@Component({
  selector: "app-editusergroup",
  templateUrl: "./editusergroup.component.html",
  styleUrls: ["./editusergroup.component.scss"]
})
export class EditUserGroupComponent implements OnInit {
  form: FormGroup;
  AllUsers: User[] = [];
  Users: User[] = [];
  filteredUsers: Observable<User[]>;
  userCtrl = new FormControl();
  //UserGroup
  //UserGroup
  // Global
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // Global

  //Door Group
  AllDoorGroups: Doorgroup[] = [];
  DoorGroups: Doorgroup[] = [];
  filteredDoorGroups: Observable<Doorgroup[]>;
  doorgroupCtrl = new FormControl();
  //Door Group

  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private fb: FormBuilder,
    private groupService: GroupService,
    private dialogRef: MatDialogRef<EditUserGroupComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: Usergroup
  ) {
    this.form = this.fb.group({
      name: [""]
    });
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: User | null) =>
        user ? this._filter(user) : this.AllUsers.slice()
      )
    );
    this.filteredDoorGroups = this.doorgroupCtrl.valueChanges.pipe(
      startWith(null),
      map((doorgroup: Doorgroup | null) =>
        doorgroup
          ? this._filterdoorgroup(doorgroup)
          : this.AllDoorGroups.slice()
      )
    );
  }

  ngOnInit() {
    this.getUsers();
    this.getDoorGroups();
    if (this.data) {
      console.log(this.data);
      this.Users = this.data.users;
      this.DoorGroups = this.data.doorGroups;
      this.form.patchValue({
        name: this.data.name
      });
    }
  }

  getUsers() {
    this.userService.getAllUsers().subscribe((data: User[]) => {
      this.AllUsers = data;
      this.Users.forEach(u => {
        this.handleFilteredUsers(u);
      });
    });
  }

  getDoorGroups() {
    this.groupService.getDoorGroups().subscribe((data: Doorgroup[]) => {
      console.log("Data received", data);
      this.AllDoorGroups = data;
      console.log("Door Groups Contains: ", this.DoorGroups);
      this.DoorGroups.forEach(u => {
        console.log("Running for each loop...");
        this.handleFilteredDoorGroup(u);
      });
      console.log("All door Groups: ", this.AllDoorGroups);
    });
  }
  close() {
    this.dialogRef.close();
  }

  save() {
    this.submitForm();
  }

  submitForm() {
    const newUserGroup: Usergroup = Object.assign({}, this.form.value);
    var formData: any = new FormData();
    formData.append("name", this.form.get("name").value);
    newUserGroup.id = null;
    if (this.data && this.data.id) {
      newUserGroup.id = this.data.id;
    }
    newUserGroup.users = this.Users;
    console.log("New User Group Data: ", newUserGroup.users);
    newUserGroup.doorGroups = this.DoorGroups;
    console.log("New door Group : ", newUserGroup.doorGroups);
    this.groupService.createUserGroup(newUserGroup).subscribe(res => {
      this.dialogRef.close(true);
    });
  }

  // USER CHIP AUTO COMPlETE...

  handleFilteredUsers(user: User) {
    console.log("Checking user", user);
    console.log("All users", this.AllUsers);
    let idx = this.AllUsers.map(e => {
      return e.id;
    }).indexOf(user.id);
    console.log("Got index", idx);
    if (idx >= 0) {
      this.AllUsers.splice(idx, 1);
    }
  }

  @ViewChild("userInput", { static: false }) userInput: ElementRef<
    HTMLInputElement
  >;
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || "").trim()) {
      return null;
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.userCtrl.setValue(null);
  }

  remove(user, indx): void {
    this.Users.splice(indx, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // Remove selected user from AllUsers
    this.handleFilteredUsers(event.option.value);
    this.Users.push(event.option.value);
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }
  private _filter(value: any): any[] {
    return this.AllUsers.filter(user => user.name.includes(value));
  }

  // DOOR CHIP AUTO COMPLETE

  @ViewChild("doorgroupInput", { static: false }) doorgroupInput: ElementRef<
    HTMLInputElement
  >;
  adddoorgroup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || "").trim()) {
      return null;
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.doorgroupCtrl.setValue(null);
  }

  removedoorgroup(doorgroup, indx): void {
    this.DoorGroups.splice(indx, 1);
  }

  selecteddoorgroup(event: MatAutocompleteSelectedEvent): void {
    // Remove selected user from AllUsers
    this.handleFilteredDoorGroup(event.option.value);
    this.DoorGroups.push(event.option.value);
    this.doorgroupInput.nativeElement.value = "";
    this.doorgroupCtrl.setValue(null);
  }
  private _filterdoorgroup(value: any): any[] {
    return this.AllDoorGroups.filter(door => door.name.includes(value));
  }

  handleFilteredDoorGroup(doorgroup: Doorgroup) {
    let idx = this.AllDoorGroups.map(e => {
      return e.id;
    }).indexOf(doorgroup.id);
    if (idx >= 0) {
      this.AllDoorGroups.splice(idx, 1);
    }
  }
}
