import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;
  public getAllUsers(): Observable<User[]> {
    let url = this.baseUrl + "Users";
    return this.httpService.get<User[]>(url);
  }

  public createUser(user: User) {
    console.log("Creating user", user);
    if (user.id === null) {
      user.id = undefined;
    }
    if (user.id) {
      console.log("User has ID, updating instead", user);
      return this.updateUser(user);
    }
    let url = this.baseUrl + "Users";
    return this.httpService
      .post(url, user)
      .pipe(catchError(() => throwError("User could not be created")));
  }

  public updateUser(user: User) {
    let url = this.baseUrl + "Users/" + user.id;
    return this.httpService
      .put(url, user)
      .pipe(catchError(() => throwError("User could not be created")));
  }

  public deleteUser(Id: Number) {
    let url = this.baseUrl + "Users/" + Id;
    return this.httpService
      .delete(url)
      .pipe(catchError(() => throwError("Could not find user to delete!")));
  }

  public UserStatusUpdate(Id: Number, active: Boolean) {
    let url = this.baseUrl + "Users/" + Id + "/active";
    return this.httpService.patch(url, { active });
  }

  // TODO CRUD Methods
}
