import { Component, OnInit, Inject } from "@angular/core";
import { DeviceService } from "src/services/device.service";
import { Firmware } from "src/models/firmware";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Device } from "src/models/device";

@Component({
  selector: "app-firmware-dialog",
  templateUrl: "./firmware-dialog.component.html",
  styleUrls: ["./firmware-dialog.component.scss"],
})
export class FirmwareDialogComponent implements OnInit {
  constructor(
    private data: DeviceService,
    private dialogRef: MatDialogRef<FirmwareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public devdata: Device
  ) {}
  firmware: Firmware[] = [];
  ngOnInit() {
    console.log("devdata: ", this.devdata);
    this.getFirmwares();
  }

  getFirmwares() {
    this.data.getDeviceFirmwares().subscribe((data: Firmware[]) => {
      this.firmware = data;
    });
  }

  //UpdateDevice function ( needs to be async)
  UpdateDeviceFirmware(firmware: Firmware) {
    console.log("Updating Device Firmware...");
    this.data.UpdateDeviceFirmware(this.devdata.id, firmware).subscribe();
    this.dialogRef.close();
  }
}
