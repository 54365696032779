import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/services/auth.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-nav-toolbar",
  templateUrl: "./nav-toolbar.component.html",
  styleUrls: ["./nav-toolbar.component.scss"]
})
export class NavToolbarComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }
}
