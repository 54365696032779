import { User } from "./user";
import { Door } from "./door";

export class Log {
  TimeStamp: Date;
  Action: ActionStatus;
  Data: String;
  UserId: number;
  User: String;
  DoorId: number;
  Door: String;
  FacilityCode: number;
  CardCore: number;
}

export enum ActionStatus {
  Unlock,
  Lock,
  Open,
  Close,
  Button,
  Scan,
  Initialize,
  Pin
}
