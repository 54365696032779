import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { Usergroup } from "src/models/usergroup";
import { Doorgroup } from "src/models/doorgroup";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class GroupService {
  constructor(private httpService: HttpClient) {}

  baseUrl = environment.baseUrl;
  public getUserGroups(): Observable<Usergroup[]> {
    let url = this.baseUrl + "UserGroups";
    return this.httpService.get<Usergroup[]>(url);
  }

  public getDoorGroups(): Observable<Doorgroup[]> {
    let url = this.baseUrl + "DoorGroups";
    return this.httpService.get<Doorgroup[]>(url);
  }

  public createUserGroup(usergroup: Usergroup) {
    if (usergroup.id === null) {
      usergroup.id = undefined;
    }

    if (usergroup.id) {
      return this.updateUserGroup(usergroup);
    }
    let url = this.baseUrl + "UserGroups";
    return this.httpService
      .post(url, usergroup)
      .pipe(catchError(() => throwError("User Group could not de Created!")));
  }

  public updateUserGroup(userGroup: Usergroup) {
    let url = this.baseUrl + "UserGroups/" + userGroup.id;
    return this.httpService
      .put(url, userGroup)
      .pipe(catchError(() => throwError("UserGroup could not be updated!")));
  }

  public createDoorGroup(doorgroup: Doorgroup) {
    if (doorgroup.id == null) {
      doorgroup.id = undefined;
    }

    if (doorgroup.id) {
      return this.updateDoorGroup(doorgroup);
    }

    let url = this.baseUrl + "DoorGroups";
    return this.httpService
      .post(url, doorgroup)
      .pipe(catchError(() => throwError("Door Group could not be created!")));
  }

  public updateDoorGroup(doorGroup: Doorgroup) {
    let url = this.baseUrl + "DoorGroups/" + doorGroup.id;
    return this.httpService
      .put(url, doorGroup)
      .pipe(catchError(() => throwError("Could not update doorGroup!")));
  }

  public deleteUserGroup(id: Number) {
    let url = this.baseUrl + "UserGroups/" + id;
    return this.httpService
      .delete(url)
      .pipe(catchError(() => throwError("Could not delete User group!")));
  }

  public deleteDoorGroup(id: Number) {
    let url = this.baseUrl + "DoorGroups/" + id;
    return this.httpService
      .delete(url)
      .pipe(catchError(() => throwError("Could not delete Door Group!")));
  }
}
