import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UsersComponent } from "./users/users.component";
import { DoorComponent } from "./door/door.component";
import { AppComponent } from "./app.component";
import { LogComponent } from "./log/log.component";
import { KeyComponent } from "./key/key.component";
import { DeviceComponent } from "./device/device.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./helpers/auth.guard";
import { GroupComponent } from "./group/group.component";

const routes: Routes = [
  { path: "", redirectTo: "users", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
  { path: "doors", component: DoorComponent, canActivate: [AuthGuard] },
  { path: "logs", component: LogComponent, canActivate: [AuthGuard] },
  { path: "device", component: DeviceComponent, canActivate: [AuthGuard] },
  { path: "keys", component: KeyComponent, canActivate: [AuthGuard] },
  { path: "group", component: GroupComponent, canActivate: [AuthGuard] },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
