import { Component, OnInit } from "@angular/core";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { Usergroup } from "src/models/usergroup";
import { Doorgroup } from "src/models/doorgroup";
import { GroupService } from "src/services/group.service";
import { EditUserGroupComponent } from "../editusergroup/editusergroup.component";
import { EditDoorGroupComponent } from "../editdoorgroup/editdoorgroup.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"]
})
export class GroupComponent implements OnInit {
  displayedColumnsUserGroup = ["name", "editColumn", "actionColumn"];
  displayedColumnsDoorGroup = ["name", "editColumn", "actionColumn"];

  constructor(private data: GroupService, private dialog: MatDialog) {}
  dataSourceuser = new MatTableDataSource<Usergroup>();
  dataSourcedoor = new MatTableDataSource<Doorgroup>();

  ngOnInit() {
    this.updateGroups();
  }

  updateUserGroup() {
    this.data.getUserGroups().subscribe((data: Usergroup[]) => {
      console.log(data);
      this.dataSourceuser.data = data;
    });
  }
  updateDoorGroup() {
    this.data.getDoorGroups().subscribe((data: Doorgroup[]) => {
      console.log(data);
      this.dataSourcedoor.data = data;
    });
  }

  updateGroups() {
    this.updateDoorGroup();
    this.updateUserGroup();
  }

  openUserGroupDialog(usergroup) {
    const dialogRef = this.dialog.open(EditUserGroupComponent, {
      data: usergroup
    });
  }

  openNewUserGroupDialog() {
    const dialogRef = this.dialog.open(EditUserGroupComponent);

    dialogRef.afterClosed().subscribe(data => {
      console.log("Data", data);
      if (data) this.updateGroups();
    });
  }
  openDoorGroupDialog(doorgroup) {
    const dialogRef = this.dialog.open(EditDoorGroupComponent, {
      data: doorgroup
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) this.updateGroups();
    });
  }

  openNewDoorGroupDialog() {
    const dialogRef = this.dialog.open(EditDoorGroupComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data) this.updateGroups();
    });
  }

  deleteUserGroup(Id: Number, grp: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: grp
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log("Confirm Dialog Data: ", data);
      if (data == true) {
        this.data.deleteUserGroup(Id).subscribe((data: any) => {
          this.updateGroups();
          console.log(data);
        });
      }
    });
  }

  deleteDoorGroup(Id: Number, grp: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: grp
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data == true) {
        this.data.deleteDoorGroup(Id).subscribe((data: any) => {
          this.updateGroups();
        });
      }
    });
  }
}
