import { Component, OnInit, Inject } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User, UserVariant, RoleVariant } from "../../models/user";
import { MatDialog } from "@angular/material/dialog";
import { CreateUserComponent } from "../create-user/create-user.component";
import { MatTableDataSource } from "@angular/material/table";
import { faTh } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  faTh = faTh;
  constructor(private data: UserService, public dialog: MatDialog) {}

  users: User[] = [];
  UserVariant = UserVariant;
  RoleVariant = RoleVariant;
  isLoading = true;
  displayedColumns = [
    "name",
    "username",
    "variant",
    "role",
    "pincode",
    "actionColumn",
    "editColumn",
  ];
  dataSource = new MatTableDataSource<User>();

  ngOnInit() {
    this.refresh();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CreateUserComponent);

    dialogRef.afterClosed().subscribe((data) => {
      console.log("Data", data);
      if (data) this.refresh();
    });
  }

  refresh() {
    console.log("Running refresh");
    this.data.getAllUsers().subscribe((data: User[]) => {
      console.log("data", data);
      this.dataSource.data = data;
    });
  }

  deleteUser(Id: Number) {
    console.log("deleting user with : ", Id);
    this.data.deleteUser(Id).subscribe((data: User) => {
      console.log("User has been deleted...", data);
    });
  }
  updateUserStatus(Id: Number, active: Boolean) {
    this.data.UserStatusUpdate(Id, active).subscribe((res) => {
      this.refresh();
    });
  }

  editUser(user) {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      data: user,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.refresh();
    });
  }
}
