import { KeyVariant } from "./key";

export class LoginUser {
  Username: String;
  Password: String;
  access_token?: String;
}

export class User {
  id?: Number;
  name: String;
  role: RoleVariant;
  pinCode: String;
  username: String;
  password: String;
  variant: UserVariant;
  isActive: boolean;
  keys: UserKey[];
}

export class UserKey {
  facilitycode: Number;
  cardcode: Number;
  variant: KeyVariant;
  isactive: boolean;
}

export enum RoleVariant {
  User = 0,
  Administrator = 1
}

export namespace RoleVariant {
  export function values() {
    return Object.keys(RoleVariant).filter(
      type => isNaN(<any>type) && type !== "values"
    );
  }
}

export enum UserVariant {
  LDAP,
  Local
}

export namespace UserVariant {
  export function values() {
    return Object.keys(UserVariant).filter(
      type => isNaN(<any>type) && type !== "values"
    );
  }
}
