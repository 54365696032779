import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DoorService } from "src/services/door.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeviceService } from "src/services/device.service";
import { Device } from "src/models/device";
import { Door } from "src/models/door";

@Component({
  selector: "app-create-door",
  templateUrl: "./create-door.component.html",
  styleUrls: ["./create-door.component.scss"]
})
export class CreateDoorComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private doorService: DoorService,
    private dialogRef: MatDialogRef<CreateDoorComponent>,
    private devService: DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: Door
  ) {
    this.form = this.fb.group({
      name: [""],
      device: [Device],
      doorNo: [""]
    });
  }

  Devices: Device[] = [];
  DoorNumbers: number[] = [1, 2];
  ngOnInit() {
    this.getDevices();
    console.log(this.data);
    if (this.data !== null) {
      this.form.setValue({
        name: this.data.name,
        device: this.data.device,
        doorNo: this.data.doorNo
      });
      console.log("Form", this.form);
    }
  }

  getDevices() {
    this.devService.getAllDevices().subscribe((data: Device[]) => {
      console.log(data);
      this.Devices = data;
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.submitForm();
  }
  submitForm() {
    var formData: any = new FormData();
    formData.append("name", this.form.get("name").value);
    formData.append("device", this.form.get("device").value);
    formData.append("doorNo", this.form.get("doorNo").value);

    const newDoor: Door = Object.assign({}, this.form.value);
    this.doorService.CreateDoor(newDoor).subscribe(res => {
      console.log("Door has been created", res);
      this.dialogRef.close(true);
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
}
