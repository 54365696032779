import { Component, OnInit, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent implements OnInit {
  @Input() name: string;
  confirm: Boolean;
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log("Data: ", this.data);
    this.name = this.data.name;
  }

  DialogYes() {
    this.dialogRef.close(true);
    this.close();
  }

  DialogNo() {
    this.dialogRef.close(false);
    this.close();
  }

  close() {}
}
