import { Component, OnInit, Inject } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User, UserVariant, RoleVariant, UserKey } from "../../models/user";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource
} from "@angular/material";
import { KeyVariant } from "src/models/key";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"]
})
export class CreateUserComponent implements OnInit {
  form: FormGroup;
  Roles = RoleVariant;
  Variants = UserVariant;
  keypanel: Boolean = true;
  hidetoggle: Boolean = true;
  displayedColumns = ["facilitycode", "cardcode", "variant"];
  datasource = new MatTableDataSource<UserKey>();
  KeyVariant = KeyVariant;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.form = this.fb.group({
      name: [""],
      password: [""],
      pincode: [""],
      isactive: [Boolean],
      role: [""],
      variant: [""],
      username: [""]
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.submitForm();
  }

  AddUser() {
    this.form = this.fb.group({
      name: [""],
      password: [""],
      pincode: [""],
      isactive: [""],
      role: [""],
      variant: [""],
      username: [""]
    });
  }

  ngOnInit() {
    this.AddUser();
    this.form.patchValue({ isactive: true });

    console.log("This.data", this.Variants.values());
    if (this.data !== null) {
      this.keypanel = false;
      this.hidetoggle = false;
      this.updateKeys(); // Here
      this.form.setValue({
        name: this.data.name,
        password: "",
        pincode: "",
        isactive: this.data.isActive,
        role: this.Roles.values()[this.data.role],
        variant: this.Variants.values()[this.data.variant],
        username: this.data.username
      });
      console.log("keys", this.data.keys); // Here
    }
  }

  updateKeys() {
    this.datasource.data = this.data.keys;
  }
  formData = new FormData();
  user: User = new User();
  PostUser() {
    this.userService.createUser(this.user).subscribe(res => console.log(res));
  }
  submitForm() {
    var formData: any = new FormData();
    formData.append("name", this.form.get("name").value);
    formData.append("password", this.form.get("password").value);
    formData.append("pincode", this.form.get("pincode").value);
    formData.append("isactive", this.form.get("isactive").value);
    formData.append("role", this.form.get("role").value);
    formData.append("variant", this.form.get("variant").value);
    formData.append("username", this.form.get("username").value);

    const newUser: User = Object.assign({}, this.form.value);
    newUser.id = null;
    if (this.data && this.data.id) {
      newUser.id = this.data.id;
    }
    console.log("NewUser", newUser);
    this.userService.createUser(newUser).subscribe(res => {
      console.log("User has been Created", res);
      this.dialogRef.close(true);
    });
  }
}
