import { Component, OnInit } from "@angular/core";
import { LogService } from "src/services/log.service";
import { Log, ActionStatus } from "src/models/log";
import { MatTableDataSource } from "@angular/material";
@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.scss"]
})
export class LogComponent implements OnInit {
  constructor(private data: LogService) {}
  logs: Log[] = [];
  displayedColumns = [
    "timestamp",
    "action",
    "data",
    "userid",
    "user",
    "doorid",
    "door",
    "facilityCode",
    "cardCode"
  ];
  dataSource = new MatTableDataSource<Log>();
  ActionStatus = ActionStatus;

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    console.log("Log Running Refresh...");
    this.data.getAllLogs().subscribe((data: Log[]) => {
      console.log("Data: ", data);
      this.dataSource.data = data;
    });
  }
}
