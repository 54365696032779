import { User } from "./user";

export class Key {
  id?: Number;
  FacilityCode: Number;
  CardCode: Number;
  Variant: KeyVariant;
  User: User;
  IsActive: boolean = true;
}

export enum KeyVariant {
  Card,
  Fob
}
