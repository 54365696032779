import { Component, OnInit } from "@angular/core";
import { DeviceService } from "src/services/device.service";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { Device } from "src/models/device";
import { FirmwareDialogComponent } from "../firmware-dialog/firmware-dialog.component";
import { UpdatedevicesfirmwareDialogComponent } from "../updatedevicesfirmware-dialog/updatedevicesfirmware-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit {
  constructor(
    private data: DeviceService,
    private dialog: MatDialog,
    private devService: DeviceService,
    private toastrService: ToastrService
  ) {}

  devices: Device[] = [];
  displayedColumns = [
    "ipaddress",
    "macaddress",
    "version",
    "editColumn",
    "syncColumn",
    "online",
  ];
  dataSource = new MatTableDataSource<Device>();

  refresh() {
    console.log("Device Running Refresh...");
    this.data.getAllDevices().subscribe((data: Device[]) => {
      data.forEach((dev) => {
        this.data.PingDevice(dev.id).subscribe((version: String) => {
          console.log("Version from API: ", version);
          dev.version = version;
        });
      });
      console.log("Devices: ", data);
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.refresh();
  }
  openfirmwareDialog(device) {
    const dialogRef = this.dialog.open(FirmwareDialogComponent, {
      data: device,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  UpdateAllDeviceFirmware() {
    const dialogRef = this.dialog.open(UpdatedevicesfirmwareDialogComponent, {
      data: this.dataSource.data,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.toastrService.success(
        "Updating Firmware on all devices...",
        "Update firmware"
      );
      this.refresh();
    });
  }

  SynchronizeDevice(id: Number) {
    this.data.SynchronizeDeviceDB(id).subscribe((data: any) => {
      console.log("Synchronizing Database for device with ID: ", id);
    });
  }

  SynchronizeAllDevices() {
    this.devices.forEach((dev) => {
      this.data.SynchronizeDeviceDB(dev.id).subscribe((data: any) => {
        console.log("Synchronizing all device DB's");
      });
    });
  }
}
